import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './TextContainer.module.scss';

interface TextContainerProps {
  center?: boolean;
  children: ReactNode;
}

const TextContainer: React.SFC<TextContainerProps> = ({ center, children }) => (
  <div
    className={classNames(styles.textContainer, {
      [styles.center]: center,
    })}
  >
    {children}
  </div>
);

export default TextContainer;
